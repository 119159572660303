/*
Description:- Renders form based on current-form value
Used In:-
- InputPage.js
*/

import { Suspense, lazy, memo } from "react";
import {
  InputInnerWrapperStyled,
  RenderInputCardStyled,
} from "../inputPage.styled";
import TermsAndConditions from "./TermsAndConditions";

const BasicDetailsForm = lazy(() => import("../BasicDetails/index"));
const DeductibleForm = lazy(() => import("../Forms/DeductibleForm"));
// const InputMembersForm = lazy(() => import("../Forms/MembersForm/MembersForm"));
const AddMembersFrom = lazy(() => import("../Forms/NewAddMembersForm"));
const LocationForm = lazy(() => import("../Forms/LocationForm"));
const MedicalHistoryForm = lazy(() => import("../Forms/MedicalHistoryForm"));
const PortabilityForm = lazy(() => import("../Forms/PortabilityForm"));
const RenewalDetailsForm = lazy(() => import("../Forms/RenewalDetailsForm"));
const FilterQuotesSearch = lazy(() => import("../Forms/FilterQuotesSearch"));

function RenderInputForms({ currentForm, posContent }) {
  return (
    <Suspense fallback={<div></div>}>
      <InputInnerWrapperStyled>
        <RenderInputCardStyled>
          <div className="renderInput_card">
            {currentForm === "basic-details" && (
              <BasicDetailsForm
                currentForm={currentForm}
                posContent={posContent}
              />
            )}

            {currentForm === "members" && (
              <AddMembersFrom posContent={posContent} />
            )}

            {currentForm.startsWith("location") && (
              <LocationForm key={currentForm} posContent={posContent} />
            )}

            {currentForm === "deductible" && (
              <DeductibleForm posContent={posContent} />
            )}

            {currentForm === "medicalHistory" && (
              <MedicalHistoryForm posContent={posContent} />
            )}

            {currentForm.startsWith("portability") && <PortabilityForm />}

            {currentForm === "renewal-details" && (
              <RenewalDetailsForm posContent={posContent} />
            )}

            {currentForm === "filterQuotes" && <FilterQuotesSearch />}
          </div>
          <TermsAndConditions />
        </RenderInputCardStyled>
      </InputInnerWrapperStyled>
    </Suspense>
  );
}

export default memo(RenderInputForms);
